import { useEffect, useState } from "react";
import DeleteIcon from '../../../assets/delete-icon.svg?react';
import PlusIcon from '../../../assets/plus-alternate-icon.svg?react';
import { Select } from '../../../components/shared/Select/Select';

export const GuestSelector = <T extends { id: number; label: string; disabled?: boolean }>({
  options,
  v,
  openGuestForm,
  onSelected,
}: {
  options: T[];
  v?: T;
  openGuestForm: () => void;
  onSelected: (o: T | null) => void;
}) => {
  const [value, setValue] = useState<null | T>(v || null);

  useEffect(() => {
    if (!v || !options.length || v?.id === value?.id) {
      return;
    }

    const option = options.find(o => o.id === v.id);
    if (!option) {
      return;
    }

    setValue(option);
  }, [options, v, value]);

  const onSelect = (index: number | null) => {
    const option = index !== null && options[index];

    if (option) {
      onSelected(option);
      setValue(option);
    }
  };

  const clearGuest = () => {
    setValue(null);
    onSelected(value);
  };

  return (
    <article className="mt-5 max-w-[640px]">
      <div className="flex justify-between pb-3">
        <p className="custom-guest-count">Гость</p>
        <span
          className="flex cursor-pointer items-center text-sm font-light text-neutral-70 hover:text-neutral-200"
          onClick={clearGuest}
        >
          Удалить гостя <DeleteIcon className="h-5 w-5" />
        </span>
      </div>
      <Select
        options={options}
        value={value}
        placeholder="Выберите гостя"
        onChange={index => onSelect(index)}
      >
        {options.map(o => (
          <Select.Option key={o.id} label={o.label} disabled={o.disabled} />
        ))}

        <div className="mb-3 border-b border-neutral-40 py-2"></div>

        <Select.CustomOption label="addGuest" onClick={openGuestForm}>
          <div className={`flex px-5 py-3 text-left`}>
            <PlusIcon className="mr-2 text-neutral-70" />
            Добавить гостя
          </div>
        </Select.CustomOption>
      </Select>
    </article>
  );
};