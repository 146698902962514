import { makeAutoObservable, reaction } from 'mobx';
import { RootStore } from './rootStore';
import { Rate, Room, getRooms } from '../apiMethods/getRooms';
import { addDays, differenceInCalendarDays, format, startOfDay } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';
import { Service, getServices } from '../apiMethods/getServices';
import { validatePromocode } from '../apiMethods/validatePromocode';
import { submitBooking } from '../apiMethods/submitBooking';

export type RateMode = 'CORP' | 'SPEC' | 'OPEN';

type GuestRoom = {
  id: string;
  params: {
    adults: number;
    childs: {
      id: number;
      count: number;
      code: string;
      use_in_calc: boolean;
    }[];
  };
};

type ReservationParams = {
  first_name: string;
  last_name: string;
  middle_name?: string;
  phone: string;
  email: string;
  city?: string;
  comment: string;
  agreed: boolean;
};

const today = startOfDay(new Date());

export class ParamsStore {
  root: RootStore;
  serviceList: ListWithCategories<Service>;
  roomList: ListWithCategories<Room>;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.root = rootStore;

    this.roomList = new ListWithCategories('Все номера')
    this.serviceList = new ListWithCategories('Все категории')
  }

  disposers: (() => void)[] = [];

  dispose() {
    this.disposers.forEach(f => f());
  }

  init() {
    this.disposers = [
      reaction(
        () => Boolean(this.root.authStore.isCompanyEmployee),
        () => {
          if (this.root.authStore.isCompanyEmployee) {
            this.setRateMode('CORP');
          } else {
            this.setRateMode('OPEN');
          }
        },
      ),
    ];
  }

  range:
    | {
        from?: Date;
        to?: Date;
      }
    | undefined = { from: today, to: addDays(today, 1) };

  setRange(newRange?: { from?: Date; to?: Date }) {
    this.range = newRange;
  }

  get from() {
    return this.appliedParams?.range?.from;
  }
  get to() {
    return this.appliedParams?.range?.to;
  }

  get nightsCount() {
    if (!this.to || !this.from) {
      return 0;
    }

    return differenceInCalendarDays(this.to, this.from);
  }

  promocode?: string;

  setPromocode(newPromocode?: string) {
    this.promocode = newPromocode;
  }

  promocodeValidationState = { success: false, failed: false, pending: false };
  setPromocodeValidationState(state: Partial<typeof this.promocodeValidationState>) {
    this.promocodeValidationState = { ...this.promocodeValidationState, ...state };
  }

  async validatePromocode(promocode: string) {
    const hotelId = this.root.configStore.hotelId;
    if (!hotelId) {
      return;
    }
    this.setPromocodeValidationState({ failed: false, success: false, pending: true });
    const { status } = (await validatePromocode(hotelId, promocode)) || {};
    if (status) {
      this.setPromocodeValidationState({ success: true, pending: false });
    } else {
      this.setPromocodeValidationState({ failed: true, pending: false });
    }
    return status;
  }

  rateMode?: RateMode = 'OPEN';

  setRateMode(rateMode: typeof this.rateMode) {
    this.rateMode = rateMode || 'OPEN';
  }

  guestRooms: GuestRoom[] = [];

  setGuestRooms(guestRooms: typeof this.guestRooms) {
    this.guestRooms = guestRooms;
  }

  countGuests(guestRooms: GuestRoom[]) {
    if (!guestRooms) {
      return [0, 0] as const;
    }
    const adults = guestRooms.reduce((sum, cur) => sum + cur.params.adults, 0);

    let childs = 0;
    for (const gr of guestRooms) {
      const count = gr.params.childs.reduce((sum, cur) => sum + cur.count, 0);
      childs += count;
    }
    return [adults, childs] as const;
  }

  get roomsCount() {
    return this?.appliedParams?.guestRooms.length;
  }

  get searchParams() {
    return {
      range: this.range,
      promocode: this.promocode,
      rateMode: this.rateMode,
      guestRooms: this.guestRooms,
    };
  }

  appliedSearchParams: null | {
    range: ParamsStore['range'];
    promocode: ParamsStore['promocode'];
    rateMode: ParamsStore['rateMode'];
    guestRooms: ParamsStore['guestRooms'];
  } = null;

  setAppliedParams(params: typeof this.appliedSearchParams) {
    this.appliedSearchParams = cloneDeep(params);
    this._selectedServices = [];
  }

  get appliedParams() {
    return this.appliedSearchParams;
  }

  get hasUnappliedParams() {
    const { guestRooms, ...currentParams } = this.searchParams;

    const { guestRooms: appliedGuestRooms, ...appliedParams } = this.appliedSearchParams || {};
    const equal = isEqual(currentParams, appliedParams);

    // rooms compared separately since each room object has a unique id
    const areRoomsEqual = isEqual(
      guestRooms.flatMap(r => r.params),
      appliedGuestRooms?.flatMap(r => r.params),
    );

    return !(equal && areRoomsEqual);
  }

  async loadRooms() {
    this.setAppliedParams(this.searchParams);

    const hotelId = this.root.configStore.hotelId;
    if (!hotelId) {
      // TODO
      throw new Error('hotelId not found');
    }

    if (!this.range?.from || !this.range?.to) {
      return;
    }

    const bodyRequest = {
      arrival: format(this.range.from, 'yyyy-MM-dd'),
      departure: format(this.range.to, 'yyyy-MM-dd'),
      promo_code: this.promocode || '',
      rooms: this.guestRooms.map(x => ({
        ...x.params,
        childs: x.params.childs.map(c => ({ ...c, code: 'CODE' })),
      })),
      rate_flow: this.rateMode || '',
    };

    this.roomList.setPending(true)
    const rooms = await getRooms(hotelId, bodyRequest);

    rooms && this.roomList.setItems(rooms);
    this.roomList.setPending(false)
  }


  getRoom(roomId: number) {
    const room = this.roomList.items.find(room => room.id === roomId);

    return room || null;
  }

  room: Room | null = null;

  selectRoom(roomId: number) {
    const room = this.getRoom(roomId);
    this.room = room || null;
  }

  rate: Rate | null = null;

  selectRate(rateId: number | null) {
    const rate = this.room?.rates.find(rate => rate.id === rateId);
    this.rate = rate || null;
  }

  get totalPrice() {
    if (!this.rate) {
      return null;
    }
    const servicesTotal = this._selectedServices.reduce((sum, x) => sum + x.price * x.quantity, 0);
    return this.rate?.total_price + servicesTotal;
  }

  guest?: {
    first_name: string;
    last_name: string;
    middle_name?: string;
    phone: string;
    email: string;
  };

  setGuest(guest: typeof this.guest) {
    this.guest = guest;
  }

  payment:
    | {
        id: number;
        payment_type: string;
        payment_alias: string;
        part: null | number;
        is_not_pay: boolean;
        hotel: number;
      }
    | undefined;

  setPayment(payment: typeof this.payment) {
    this.payment = payment;
  }

  get bookingParams() {
    const { range, promocode, rateMode, guestRooms } = this.appliedSearchParams || {};
    const { from, to } = range || {};

    const { room, rate, payment } = this;
    if (!from || !to || !room || !rate || !rateMode || !payment || !guestRooms) {
      return null;
    }

    return {
      from,
      to,
      room,
      rate,
      promocode,
      rateMode,
      guestRooms,
      payment,
    };
  }

  async submitReservation({ comment, ...data }: ReservationParams) {
    const getNotes = (comment: string) => {
      const id = this.root.configStore.commentNoteId;
      const code = this.root.configStore.commentNoteCode;

      if (!comment || !id || !code) {
        return [];
      }

      return [{ id, code, text: comment }];
    };

    const hotelId = this.root.configStore.hotelId;

    const notes = getNotes(comment);

    const user = { ...data };

    if (!hotelId || !this.bookingParams) {
      alert('параметры бронирования не найдены');
      return;
    }

    const { from, to, room, rate, promocode, rateMode, guestRooms, payment } = this.bookingParams;

    const services = this.selectedServices;

    const arrival = format(from, 'yyyy-MM-dd');
    const departure = format(to, 'yyyy-MM-dd');

    const response = await submitBooking({
      hotel_id: hotelId,
      guest: user,
      arrival,
      departure,
      room: room,
      rate: rate,
      promo_code: promocode || '',
      rate_flow: rateMode,
      rooms: guestRooms?.flatMap(room => room.params),
      payment: payment,
      services,
      notes,
    });

    return response;
  }

  async fetchServices() {
    const hotelId = this.root.configStore.hotelId;
    if (!hotelId) {
      // TODO
      throw new Error('hotelId not found');
    }

    if (!this.range?.from || !this.range?.to) {
      return;
    }

    this.serviceList.setPending(true);

    const bodyRequest = {
      arrival: format(this.range.from, 'yyyy-MM-dd'),
      departure: format(this.range.to, 'yyyy-MM-dd'),
      promo_code: this.promocode || '',
      rooms: this.guestRooms.map(x => ({
        ...x.params,
        childs: x.params.childs.map(c => ({ ...c, code: 'CODE' })),
      })),
      rate_flow: this.rateMode || ('OPEN' as RateMode),
    };

    const services = await getServices(hotelId, bodyRequest);
    services && this.setServices(services);
    services && this.serviceList.setItems(services);

    this.serviceList.setPending(false);
  }

  services: Service[] = [];
  setServices(services: Service[]) {
    this.services = services;
  }

  getService(serviceId: number) {
    return this.services.find(s => s.id === serviceId);
  }

  private _selectedServices: {
    serviceId: number;
    subServiceId?: number;
    name: string;
    price: number;
    priceAnnotation?: string;
    quantity: number;
    comment?: string;
  }[] = [];

  get selectedServices() {
    return this._selectedServices;
  }

  getSelectedService(serviceId: number) {
    return this._selectedServices.find(x => x.serviceId === serviceId);
  }

  isServiceSelected(serviceId: number, subServiceId?: number) {
    const found = this._selectedServices.find(
      x => x.serviceId == serviceId && x.subServiceId == subServiceId,
    );
    return Boolean(found);
  }

  toggleService(service: {
    serviceId: number;
    name: string;
    subServiceId?: number;
    price: number;
    quantity: number;
  }) {
    if (this._selectedServices.find(x => x.serviceId === service.serviceId)) {
      this._selectedServices = this._selectedServices.filter(
        x => x.serviceId !== service.serviceId,
      );
    } else {
      this._selectedServices.push(service);
    }
  }

  toggleQuantityService(service: {
    serviceId: number;
    quantity: number;
    name: string;
    price: number;
    priceAnnotation: string;
  }) {
    const exists = this._selectedServices.find(x => x.serviceId == service.serviceId);

    if (!exists) {
      this._selectedServices.push(service);
      return;
    }

    this._selectedServices = this._selectedServices.map(x => {
      if (x.serviceId === service.serviceId) {
        x.quantity = service.quantity;
      }
      return x;
    });

    this._selectedServices = this._selectedServices.filter(x =>
      x.serviceId === service.serviceId ? x.quantity > 0 : x,
    );
  }

  toggleSubService(service: {
    serviceId: number;
    subServiceId?: number;
    name: string;
    price: number;
    quantity: number;
  }) {
    if (
      this._selectedServices.find(
        x => x.serviceId === service.serviceId && x.subServiceId === service.subServiceId,
      )
    ) {
      this._selectedServices = this._selectedServices.filter(x => {
        return x.serviceId !== service.serviceId || x.subServiceId !== service.subServiceId;
      });
    } else {
      this._selectedServices.push(service);
    }
  }

}

class ListWithCategories<T extends { category: string}> {
  constructor(private readonly allLabel: string) {
    this.category = allLabel

    makeAutoObservable(this)
  }

  category: string | null 
  setCategory(category: string | null) {
    this.category = category;
  }

  items: T[] = []
  setItems(items: T[]) {
    this.items = items;
    const categories = this.categories;
    if (this.category && !categories.includes(this.category)) {
      this.category = this.allLabel;
    }
  }

  getListByCategory() {
    if (!this.category || this.category === this.allLabel) {
      return this.items
    }
    return this.items.filter(item => item.category === this.category);
  }

  pending = false;

  setPending(state: boolean) {
    this.pending = state;
  }

  get categories() {
    const categories = [...new Set(this.items.map(item => item.category).filter(x => x))];
    if (!categories.length) {
      return []
    }

    return [this.allLabel, ...categories]
  }
}