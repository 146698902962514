import { api } from '../utils/api';

export type RefundInfo = {
  can_return: boolean;
  amount: number;
  description: string;
  reservation_payment_id: number | null;
};

export const getRefundInfo = async (hotelId: number, reservationId: number) => {
  const url = `/api/v1/check-refund-money-balance/${hotelId}/`;

  const response = await api.post<RefundInfo>(url, { reservation_id: reservationId });
  return response;
};
