import { Button } from './shared/Button/Button';
import { Dialog } from './shared/Dialog/Dialog';
import { HeadingUI2 } from './shared/Heading';
import CloseIcon from '../assets/close-icon.svg?react';
import { useRootStore } from '../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { AuthError } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { rootStore } from '../stores/rootStore';

export const ErrorModal = observer(() => {
  const { errorStore } = useRootStore();

  const navigate = useNavigate();

  const closeErrorModal = () => {
    const error = errorStore.error;
    if (error instanceof AuthError) {
      navigate('/welcome');
      rootStore.configStore.init();
      return errorStore.clearError();
    }
    errorStore.clearError();
  };

  if (!errorStore.error) {
    return null;
  }

  return (
    <>
      <Dialog isOpen={true} onClose={closeErrorModal} className="!h-min">
        <div className="fixed bottom-0 w-full bg-neutral-0 xl:static">
          <div className="p-6 pb-8 pl-8 xl:w-[571px]">
            <div>
              <HeadingUI2 className="mb-5 pr-[52px] font-medium">
                {errorStore.error.name}
              </HeadingUI2>
              <p className="mb-6 font-light">{errorStore.error.message}</p>
              <Button onClick={closeErrorModal} className=" w-full xl:w-auto">
                Принять
              </Button>
            </div>
            <Button
              style="primary"
              variant="link"
              onClick={closeErrorModal}
              className="absolute right-5 top-2 !p-[14px] xl:right-6 xl:top-6"
            >
              <CloseIcon className={``} />
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});
