import { api } from "../utils/api";

export async function cancelService({
  hotelId,
  bookingId,
  serviceId,
}: {
  hotelId: number;
  bookingId: number;
  serviceId: number;
}) {
  const bodyRequest = {
    reservation_id: bookingId,
    service_id: serviceId,
  };

  const url = `/api/v1/refuse-service/${hotelId}/`;
  await api.post(url, bodyRequest);
}
