import { ReactNode } from 'react';
import { Button } from '../../components/shared/Button/Button';

export const ButtonWithIcon = ({
  text,
  onClick,
  icon,
  pending
}: {
  text: string;
  onClick: () => void;
  icon: ReactNode;
  pending?: boolean;
}) => {
  return (
    <Button
      variant="link"
      style="secondary"
      className="flex items-center gap-2 !p-0 text-sm"
      onClick={onClick}
      pending={pending}
    >
      {text} {icon}
    </Button>
  );
};
