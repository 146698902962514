import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { RouterProvider, useParams } from 'react-router-dom';
import { ErrorPage } from './views/ErrorPage.tsx';
import { ParamsSetup } from './views/ParamsSetup.tsx';
import { RoomList } from './views/RoomList.tsx';
import { RoomDetails } from './Room/RoomDetails.tsx';
import { Checkout } from './Checkout.tsx';
import { Rates } from './views/Rates/Rates.tsx';
import { AuthPage } from './views/AuthPage/AuthPage.tsx';
import { ProtectedRoute } from './components/ProtectedRoute.tsx';
import { UserForm } from './views/Rates/UserForm.tsx';
import { AuthWelcome } from './views/AuthPage/WelcomePage.tsx';
import { Account } from './views/Account/Account.tsx';
import { BookingInfo } from './views/Account/BookingInfo/BookingInfo.tsx';
import { BookingList } from './views/Account/BookingList.tsx';
import { Profile } from './views/Account/ProfileTab/ProfileTab.tsx';
import "./initSentry.ts";
import { sentryCreateBrowserRouter } from './initSentry.ts';
import { Services } from './views/Services/Services.tsx';

const OnSuccess = () => {
  const urlParams = useParams();
  useEffect(() => {
    console.log(urlParams);
  }, []);
  return <p>onSuccess</p>;
};

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <ParamsSetup />
          </ProtectedRoute>
        ),
      },
      {
        path: '/account',
        element: (
          // <ProtectedRoute>
          <Account />
          // </ProtectedRoute>
        ),
        children: [
          { path: '/account', element: <BookingList /> },
          { path: 'info', element: <Profile /> },
        ],
      },
      { path: '/booking-list/:bookingId', element: <BookingInfo /> },
      {
        path: '/api/v1/users/login-sber-id',
        element: <OnSuccess />,
      },
      {
        path: '/welcome',
        element: <AuthWelcome />,
      },
      {
        path: '/auth',
        element: <AuthPage />,
      },
      {
        path: 'rooms/:roomId?/*',
        element: (
          <ProtectedRoute>
            <RoomList />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'details',
            element: <RoomDetails />,
          },
        ],
      },
      {
        path: '/rates',
        element: (
          <ProtectedRoute>
            <Rates />
          </ProtectedRoute>
        ),
      },
      {
        path: '/services',
        element: (
          <ProtectedRoute>
            <Services />
          </ProtectedRoute>
        ),
      },
      {
        path: '/form',
        element: (
          <ProtectedRoute>
            <UserForm />
          </ProtectedRoute>
        ),
      },
      {
        path: 'checkout/:roomId',
        element: <Checkout />,
      },
    ],
  },
  {
    path: 'about/:id?',
    element: <div>This is about page</div>,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
