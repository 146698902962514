import { ReactNode } from 'react';

export const Heading4 = ({ children }: { children: ReactNode }) => (
  <h4 className="mb-8 text-2xl font-light uppercase leading-9 text-neutral-700">{children}</h4>
);

export const HeadingUI4 = ({ children, className }: { children: ReactNode; className?: string }) => (
  <h4 className={`mb-2 text-2xl text-neutral-700 ${className}`}>{children}</h4>
);

export const HeadingUI2 = ({ children, className }: { children: ReactNode, className?: string }) => (
  <h2 className={`mb-2 text-[22px] leading-[24px] xl:text-3xl text-neutral-700 ${className}`}>{children}</h2>
);

export const Heading3 = ({ children, className }: { children: string; className?: string }) => (
  <h3
    className={`mb-4 font-title text-[20px] font-light uppercase leading-7 text-neutral-700 xl:text-[28px] xl:leading-9 ${className}`}
  >
    {children}
  </h3>
);


export const HeadingUI3 = ({ children, className }: { children: ReactNode, className?: string }) => (
  <h3 className={`mb-2 text-[22px] leading-6  xl:text-[32px] xl:leading-[36px] font-medium text-neutral-700 ${className}`}>{children}</h3>
);

export const Heading2 = ({ children, className }: { children: string; className?: string }) => (
  <h2
    className={`mb-4 font-title text-2xl font-light uppercase text-neutral-700 xl:text-[32px] xl:leading-10 ${className}`}
  >
    {children}
  </h2>
);

export const Heading1 = ({ children, className }: { children: ReactNode; className?: string }) => (
  <h1
    className={`mb-4 font-title text-[32px] font-light uppercase leading-10 text-neutral-700 xl:text-[40px] xl:leading-[56px] ${className}`}
  >
    {children}
  </h1>
);
