import noun from 'plural-ru';
import { ReactNode } from 'react';
import { Skeleton } from '../components/Skeleton';
import { formatMoney } from '../utils/formatMoney';

export const PriceAndButtons = ({
  price,
  nights,
  children,
  isCompact,
  loading,
}: {
  price: number;
  nights: number;
  children: ReactNode;
  isCompact?: boolean;
  loading?: boolean;
}) => {
  const n = noun(nights, 'ночь', 'ночи', 'ночей');

  return (
    <div className="mt-4 flex-1 items-center justify-end xl:mt-8 xl:flex">
      <div className={`flex ${isCompact && 'custom-compact-price'}`}>
        <Skeleton loading={loading} className="h-[52px] w-2/3 mb-3 xl:mb-0">
          <p
            className={`mb-3 text-[28px] font-light text-neutral-900   xl:mb-0 xl:inline-flex xl:flex-row xl:items-end xl:text-[32px] `}
          >
            <span className="mr-4 leading-8 xl:mr-3 ">
              от {formatMoney(price)} &#8381;
            </span>
            <span className="text-[16px] font-light leading-5 text-neutral-70  xl:text-[18px]">
              / за {nights} {n}
            </span>
          </p>
        </Skeleton>
      </div>
      {children}
    </div>
  );
};
