import { Button } from '../../../components/shared/Button/Button';
import { HeadingUI3 } from '../../../components/shared/Heading';
import { useRootStore } from '../../../stores/useRootStore';
import PencilIcon from '../../../assets/pencil-icon.svg?react';
import BinIcon from '../../../assets/bin-icon.svg?react';
import PlusIcon from '../../../assets/plus-alternate-icon.svg?react';
import { AccompanyingGuest } from '../../../apiMethods/getUserInfo';
import { observer } from 'mobx-react-lite';
import { Dialog } from '../../../components/shared/Dialog/Dialog';
import { useEffect, useState } from 'react';
import { GuestForm } from '../GuestForm';

const getFullName = (user: { first_name: string; last_name: string; middle_name?: string }) =>
  `${user?.last_name} ${user?.first_name} ${user?.middle_name}`;

const UserInfoItem = ({ label, value }: { label: string; value: string }) => (
  <div>
    <label htmlFor="" className="text-sm font-medium">
      {label}
    </label>
    <p className="flex-1 text-base font-light leading-5">{value}</p>
  </div>
);

export const Profile = observer(() => {
  const { authStore, bookingsStore, confirmStore } = useRootStore();

  const user = authStore.user;

  const [guest, setGuest] = useState<AccompanyingGuest | null>(null);

  const [isGuestModalOpen, setGuestModalOpen] = useState(false);

  const onGuestFormClose = () => {
    setGuest(null);
    setGuestModalOpen(false);
  };

  useEffect(() => {
    guest && setGuestModalOpen(true);
  }, [guest]);

  const onDeleteGuest = async (guestId: number) => {
    const confirmed = await confirmStore.confirm({
      title: 'Внимание!',
      text: 'Вы действительно хотите удалить гостя?',
    });
    confirmed && bookingsStore.deleteGuest(guestId);
  };

  const superUsers = import.meta.env.VITE_SUPER_USER ?? ('' as string);
  const showButton = superUsers.split(',').includes(`${authStore?.user?.id}`);

  return (
    <div className="container mx-auto max-w-[870px] xl:mb-10 xl:mt-10">
      <div className="bg-neutral-10 px-5">
        <section className="space-y-4 py-6">
          <HeadingUI3>Личные данные</HeadingUI3>
          <UserInfoItem label="ФИО" value={user ? getFullName(user) : ''}></UserInfoItem>
          <UserInfoItem label="Телефон" value={`${user?.phone}`}></UserInfoItem>
          <UserInfoItem label="E-mail" value={`${user?.email}`}></UserInfoItem>
          {user?.balance_unit_sber && <UserInfoItem label={user?.balance_unit_sber?.name} value={user.balance_unit_sber.sale_comment}></UserInfoItem>}
        </section>

        <section className="space-y-4 py-6">
          <HeadingUI3>Гости</HeadingUI3>

          {authStore.guestList.map((guest) => (
            <article key={guest.id} className="">
              <p className="mb-1 text-sm font-medium">{guest.relation_degree.name}</p>
              <span className="font-light">{getFullName(guest)}</span>
              <div className="mt-2 flex space-x-4">
                <Button
                  variant="link"
                  style="secondary"
                  className="flex items-center gap-2 !p-0 text-sm"
                  onClick={() => {
                    setGuest(guest);
                  }}
                >
                  Изменить <PencilIcon />
                </Button>
                <Button
                  variant="link"
                  style="secondary"
                  className="flex items-center gap-2 !p-0 text-sm"
                  onClick={() => onDeleteGuest(guest.id)}
                >
                  Удалить <BinIcon />
                </Button>
              </div>
            </article>
          ))}

          <Button
            variant="link"
            className="flex items-center gap-2 p-0 text-lg"
            onClick={() => setGuestModalOpen(true)}
          >
            <PlusIcon /> Добавить гостя
          </Button>

          <Dialog
            isOpen={isGuestModalOpen}
            onClose={onGuestFormClose}
            className="!h-fit w-full xl:w-[571px]"
          >
            <div className="fixed bottom-0 h-fit w-full bg-neutral-0 p-6 xl:static xl:bottom-auto xl:py-8 ">
              {isGuestModalOpen && (
                <GuestForm
                  onClose={onGuestFormClose}
                  guest={guest ? { ...guest, relation: guest?.relation_degree.name } : undefined}
                />
              )}
            </div>
          </Dialog>
        </section>
      </div>
      {showButton && (
        <Button
          onClick={() => {
            throw new Error('throw error for sentry checking');
          }}
        >
          Check Sentry
        </Button>
      )}
    </div>
  );
});
