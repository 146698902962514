import CloseIcon from '../../assets/close-icon.svg?react';

export const CloseModalButton = ({ onClose }: { onClose: () => void }) => (
  <span
    className="fixed z-[1] m-3 bg-neutral-900 p-2.5 text-neutral-0 opacity-35 hover:cursor-pointer hover:opacity-65 xl:p-3.5"
    onClick={onClose}
  >
    <CloseIcon />
  </span>
);
