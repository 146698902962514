import { api } from '../utils/api';

export type Kinship = {
    id: number;
    name: string;
}

export const getKinshipTypes = () => {
  const url = `/api/v1/users/guest/relation_degrees/`;

  const res = api.get<Kinship[]>(url);
  return res;
};
