import { format } from 'date-fns';
import { ReactNode } from 'react';
import { BookingInvoice } from '../../../apiMethods/getBookingInfo';
import { Button } from '../../../components/shared/Button/Button';
import { HeadingUI4 } from '../../../components/shared/Heading';
import { Chip } from '../components/Chip';
import { Price } from '../components/Price';
import { Nights } from '../components/Nights';
import { Skeleton } from '../../../components/Skeleton';

const formatPaymentTime = (time: string) => format(new Date(time), 'dd.MM.yyyy, HH:mm:ss');

const invoiceConfig: {
  [P in 'not paid' | 'paid' | 'readOnly']: {
    text: string;
    button: (link: string) => ReactNode;
    status: (invoice: BookingInvoice) => string;
  };
} = {
  paid: {
    text: 'Оплачено',
    button: () => null,
    status: (payment) => `Оплачено ${formatPaymentTime(payment.paid_time)}`,
  },
  'not paid': {
    text: 'К оплате',
    button: (paymentLink: string) => (
      <a href={paymentLink} target="_blank">
        <Button>Оплатить счет</Button>
      </a>
    ),
    status: (payment) => `Ожидает оплаты до ${formatPaymentTime(payment.cancel_reservation_date)}`,
  },
  readOnly: {
    text: 'Не активен',
    button: () => null,
    status: (payment) =>
      `Действие ссылки закончилось ${formatPaymentTime(payment.link_expiration_date)}`,
  },
};

export const Invoice = ({
  payment,
  readOnly,
  loading,
}: {
  payment: BookingInvoice;
  readOnly?: boolean;
  loading: boolean;
}) => {
  const status = readOnly ? 'readOnly' : payment.paid ? 'paid' : 'not paid';
  const config = invoiceConfig[status];

  return (
    <article className="mb-8 pb-5 last:mb-0 last:pb-0">
      <div className="flex items-center gap-5">
        <Skeleton loading={loading} className="h-8 w-[95px]">
          <HeadingUI4 className={`custom-invoice-count`}>Счёт</HeadingUI4>
        </Skeleton>
        {!loading && <Chip className="hidden xl:block">{config.status(payment)}</Chip>}
      </div>
      <div className="mt-4">
        <p className="text-xl font-medium leading-[24px] text-neutral-900">
          <Skeleton loading={loading} className="h-6 w-[95px]">
            {config.text}
          </Skeleton>
        </p>
        <div className="mb-4 mt-3 flex items-end">
          <Skeleton loading={loading} className="mr-2 h-[30px] w-[60px]">
            <Price>{Number(payment.amount).toLocaleString('ru')}</Price>
          </Skeleton>
          <Skeleton loading={loading} className="h-[20px] w-[128px]">
            <Nights>{payment.payment_selected_method}</Nights>
          </Skeleton>
        </div>
        {!readOnly && config.button(payment.payment_link)}
      </div>
    </article>
  );
};
