import DOMPurify from 'dompurify';
import { useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import ChevronUpIcon from '../../assets/chevron-up-icon.svg?react';
import HTMLParser from '../HTMLParser/HTMLParser';

const ResponsibeHTMLEllipsis = responsiveHOC()(HTMLEllipsis);

const defaultLabel = (o: boolean) => (o ? 'Скрыть' : 'Подробнее');

/** Static clamped html element */
export const ClampedHtml = ({ html, maxLine }: { html: string; maxLine: number }) => {
  const trustedHtml = DOMPurify.sanitize(html);

  return (
    <ResponsibeHTMLEllipsis
      unsafeHTML={trustedHtml}
      maxLine={maxLine}
      ellipsis="..."
      trimRight
      basedOn="letters"
    />
  );
};

const Toggler = ({
  onClick,
  isOpen,
  label,
}: {
  onClick: () => void;
  isOpen: boolean;
  label: (isOpen: boolean) => string;
}) => (
  <span className="mb-1 flex cursor-pointer font-light text-primary-300" onClick={onClick}>
    {label(isOpen)}
    <ChevronUpIcon className={`custom-icon ml-2 ${isOpen ? '' : 'rotate-180'}`} />
  </span>
);

/** Toggleable html element */
export const ClampableHtml = ({
  html,
  maxLine = 3,
  label = defaultLabel,
}: {
  html: string;
  maxLine?: number;
  label?: (isOpen: boolean) => string;
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {isOpen ? <HTMLParser html={html} /> : <ClampedHtml html={html} maxLine={maxLine} />}
      <Toggler isOpen={isOpen} onClick={() => setOpen(s => !s)} label={label} />
    </>
  );
};
