import { useNavigate } from "react-router-dom";
import { useRootStore } from "./stores/useRootStore";
import { useEffect } from "react";

// Performs redirect to home page when search params is empty
export const useRedirectToHomePage = () => {
  const { paramsStore } = useRootStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!paramsStore.appliedSearchParams) {
      navigate('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
