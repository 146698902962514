import { useParams } from 'react-router-dom';
import { HeadingUI3 } from '../../../components/shared/Heading';
import { Chip } from '../components/Chip';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button } from '../../../components/shared/Button/Button';
import { Dialog } from '../../../components/shared/Dialog/Dialog';
import { GuestForm } from '../GuestForm';
import { useRootStore } from '../../../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { BackLink } from '../../Rates/Rates';
import { formatDate, parseDate } from '../../../utils/dates';
import { useNightsCounter } from '../../../utils/useNightsCounter';

import './bookingInfo.css';
import noun from 'plural-ru';
import { Invoice } from './Invoice';
import { BookingCancellation } from './BookingCancellation';
import { AccompanyingGuest } from '../../../apiMethods/getUserInfo';
import { WarningModal } from '../../../components/WarningModal';
import { Skeleton } from '../../../components/Skeleton';
import { TableRow } from '../components/TableRow';
import { NotFoundPlug } from '../../../components/Plug/NotFoundPlug';
import { ServiceItem } from './BookedService';
import { GuestSelector } from './BookedGuests';

const getGuestLabel = (g: { first_name: string; last_name: string }) =>
  `${g.first_name} ${g.last_name}`;

const Annotation = ({ children }: { children: ReactNode }) => (
  <p className="mb-8 max-w-[508px] text-sm font-light">{children}</p>
);

export const BookingInfo = observer(() => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { authStore, bookingsStore } = useRootStore();

  const loadingBooking = bookingsStore.loadingBookingInfo;

  const { accompanyingGuests, totalGuestCount } = bookingsStore;

  const readOnly = bookingsStore.bookingInfo?.read_only;

  const { bookingInfo: booking, guestListUpdatePending } = bookingsStore;

  useEffect(() => {
    bookingId && bookingsStore.loadBookingInfo(bookingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  const [guestSlots, setGuestSlots] = useState<({ id: number; label: string } | undefined)[]>(
    Array.from(new Array(totalGuestCount || 0)),
  );

  useEffect(() => {
    const slots: ({ id: number; label: string } | undefined)[] = Array.from(
      new Array(totalGuestCount || 0),
    );

    accompanyingGuests?.forEach((guest, i) => {
      slots[i] = { ...guest, label: getGuestLabel(guest) };
    });
    setGuestSlots(slots);
  }, [accompanyingGuests, totalGuestCount]);

  const guestList = useMemo(() => {
    const disabledGuests = guestSlots.map(gs => gs?.id);
    return authStore.guestList.map(guest => ({
      ...guest,
      label: getGuestLabel(guest),
      disabled: disabledGuests.includes(guest.id),
    }));
  }, [authStore.guestList, guestSlots]);

  const onGuestChange = (guest: { id: number; label: string } | null, slotIndex: number) => {
    if (!guest) {
      return;
    }

    const slot = guestSlots[slotIndex];
    if (slot?.id) {
      setGuestSlots(slots => {
        const newSlots = [...slots];
        newSlots[slotIndex] = slot.id === guest.id ? undefined : guest;
        return newSlots;
      });
    } else {
      setGuestSlots(slots => {
        const newSlots = [...slots];
        newSlots[slotIndex] = guest;
        return newSlots;
      });
    }
  };

  const [guestListUpdateModal, setGuestListUpdateModal] = useState(false);

  const saveGuestList = async () => {
    if (!bookingId) {
      return;
    }
    const guestIds = guestSlots.map(g => g?.id).filter((x): x is number => !!x);

    await bookingsStore.saveGuestList(bookingId, guestIds);
    setGuestListUpdateModal(true);
  };

  const [isNewGuestModalOpen, setNewGuestModalOpen] = useState(false);
  const [guestSlot, setGuestSlot] = useState<number | null>(null);

  const onGuestCreated = (guest: AccompanyingGuest) => {
    if (guestSlot === null) {
      return;
    }
    setGuestSlots(slots => {
      const newSlots = [...slots];
      newSlots[guestSlot] = { ...guest, label: getGuestLabel(guest) };
      return newSlots;
    });

    setGuestSlot(null);
  };

  const { arrival, departure, adults, childs, rooms_count } = booking || {};

  const nightsStat =
    arrival && departure
      ? useNightsCounter({ from: parseDate(arrival), to: parseDate(departure) })
      : '-';

  const roomsStat = rooms_count
    ? `${rooms_count} ` + noun(rooms_count, 'комната', 'комнаты', 'комнат')
    : '';

  const childsCount = childs?.reduce((count, x) => x.quantity + count, 0);
  const childsStat = childsCount
    ? `, ${childsCount} ` + noun(childsCount, 'ребёнок', 'ребёнка', 'детей')
    : '';
  const adultsStat = adults ? `${adults} ` + noun(adults, 'взрослый', 'взрослых', 'взрослых') : '';

  const guestsStat = adultsStat + childsStat;

  if (!loadingBooking && !booking) {
    return (
      <BookingInfoContainer>
        <NotFoundPlug title="Бронирование не найдено" text=""></NotFoundPlug>
      </BookingInfoContainer>
    );
  }

  return (
    <BookingInfoContainer>
      <div className=" bg-neutral-10 px-5 xl:mt-6 xl:px-8">
        <section className="py-6 ">
          <div className="mb-5 flex flex-col justify-between xl:flex-row">
            <p className="text-lg font-light text-neutral-700">
              <Skeleton loading={loadingBooking} className="h-7 w-[250px]">
                Заказ № {booking?.crs_number}
              </Skeleton>
            </p>
            <div className="mt-2 flex flex-wrap gap-2 xl:mt-0">
              {!loadingBooking && (
                <>
                  {booking?.payment_status && (
                    <Chip className="block">{booking?.payment_status}</Chip>
                  )}
                  {booking?.status && <Chip className="block">{booking?.status}</Chip>}
                </>
              )}
            </div>
          </div>
          <HeadingUI3>
            <Skeleton loading={loadingBooking} className="h-9 w-4/5">
              {booking?.room_type.name}
            </Skeleton>
          </HeadingUI3>
          <p className="text-xl font-medium leading-[24px]">
            <Skeleton loading={loadingBooking} className="h-6 w-[240px]">
              {booking?.arrival && formatDate(booking?.arrival)} -{' '}
              {booking?.departure && formatDate(booking?.departure)}
            </Skeleton>
          </p>

          <table className="mt-4 font-light text-neutral-700">
            <TableRow
              label="Проживание"
              loading={loadingBooking}
              skeletonClass={['w-[110px]', 'w-[150px]']}
            >
              {nightsStat}, {roomsStat}
            </TableRow>
            <TableRow
              label="Гости"
              loading={loadingBooking}
              skeletonClass={['w-[50px]', 'w-[80px]']}
            >
              {guestsStat}
            </TableRow>
            <TableRow
              label="Тариф"
              loading={loadingBooking}
              skeletonClass={['w-[60px]', 'w-[160px]']}
            >
              {booking?.rate.name}
            </TableRow>
          </table>
        </section>
        {!readOnly || accompanyingGuests?.length ? (
          <section className="custom-guest-counter border-b border-t border-neutral-40 pb-8 pt-6">
            <HeadingUI3 className="mb-8">
              <Skeleton loading={loadingBooking} className="h-9 w-[80px]">
                Гости
              </Skeleton>
            </HeadingUI3>
            {loadingBooking && (
              <div className="flex gap-4">
                <Skeleton loading className="h-5 w-[140px]"></Skeleton>
                <Skeleton loading className="h-5 w-[50px]"></Skeleton>
              </div>
            )}

            {readOnly
              ? !loadingBooking &&
                accompanyingGuests?.map(guest => (
                  <p className="text-lg">
                    {guest.first_name} {guest.last_name}
                    <span className="ml-4 font-light text-neutral-70">
                      {guest.relation_degree.name}
                    </span>
                  </p>
                ))
              : guestSlots.map((slot, i) => (
                  <GuestSelector
                    key={i}
                    options={guestList as { id: number; label: string }[]}
                    v={slot ? { id: slot.id, label: slot.label } : undefined}
                    openGuestForm={() => {
                      setNewGuestModalOpen(true);
                      setGuestSlot(i);
                    }}
                    onSelected={g => onGuestChange(g, i)}
                  />
                ))}

            {readOnly == false && (
              <Button className="mt-8" onClick={saveGuestList} disabled={guestListUpdatePending}>
                Сохранить изменения
              </Button>
            )}
          </section>
        ) : null}
        {booking?.reservation_services.length ? (
          <section className="pb-8 pt-6">
            <HeadingUI3 className="mb-7">
              <Skeleton loading={loadingBooking} className="h-9 w-[260px]">
                Дополнительные услуги
              </Skeleton>
            </HeadingUI3>

            {booking?.reservation_services.map(service => (
              <ServiceItem
                key={service.id}
                service={service}
                bookingId={bookingId}
                loading={loadingBooking}
              />
            ))}
          </section>
        ) : null}
        <section className="custom-invoice-counter pb-8 pt-6">
          <HeadingUI3>
            <Skeleton loading={loadingBooking} className="h-9 w-[110px]">
              Оплата
            </Skeleton>
          </HeadingUI3>
          <Annotation>
            <Skeleton loading={loadingBooking} className="w-max-[500px] h-5 w-full">
              Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз
              ПАО СБЕРБАНК.
            </Skeleton>
          </Annotation>

          {booking?.reservation_payments.map((payment, idx) => (
            <Invoice key={idx} payment={payment} readOnly={readOnly} loading={loadingBooking} />
          ))}
        </section>

        {!readOnly && (
          <section className="pb-8 pt-6">
            <HeadingUI3>
              <Skeleton loading={loadingBooking} className="h-9 w-full max-w-[400px]">
                Отмена бронирования
              </Skeleton>
            </HeadingUI3>

            <Annotation>
              <Skeleton loading={loadingBooking} className="h-5 w-[250px]">
                Оформите отмену бронирования
              </Skeleton>
            </Annotation>
            <Skeleton loading={loadingBooking} className="h-[50px] w-[200px]">
              <BookingCancellation />
            </Skeleton>
          </section>
        )}
        <Dialog
          isOpen={Boolean(isNewGuestModalOpen)}
          onClose={() => setNewGuestModalOpen(false)}
          className="!h-fit w-full xl:w-[571px]"
        >
          <div className="fixed bottom-0 h-fit w-full bg-neutral-0 p-6 xl:static xl:bottom-auto xl:py-8 ">
            <GuestForm
              onClose={() => setNewGuestModalOpen(false)}
              onGuestCreated={onGuestCreated}
            />
          </div>
        </Dialog>

        <WarningModal
          title="Изменения сохранены"
          isOpen={guestListUpdateModal}
          onClose={() => setGuestListUpdateModal(false)}
        >
          Список гостей обновлён
        </WarningModal>
      </div>
    </BookingInfoContainer>
  );
});

const BookingInfoContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="container mx-auto mt-8 max-w-[870px] xl:mb-10 xl:mt-10">
      <div className="mb-4 px-5">
        <BackLink to={'/account'}>К списку бронирований</BackLink>
      </div>

      {children}
    </div>
  );
};
