import { useState } from "react";
import { useRootStore } from "../../../stores/useRootStore";
import { cancelService } from "../../../apiMethods/cancelService";
import { Skeleton } from "../../../components/Skeleton";
import { format } from "date-fns/format";
import { ButtonWithIcon } from "../ButtonWithIcon";
import BinIcon from '../../../assets/bin-icon.svg?react';

type ServiceProps = {
  id: number;
  price: string;
  paid: boolean;
  quantity: number;
  refuse_guest: boolean;
  refuse_time: null | string;
  service: {
    id: number;
    name: string;
  };
  sub_service: null | {
    id: number;
    name: string;
  };
};

export const ServiceItem = ({
  loading,
  bookingId,
  service,
}: {
  service: ServiceProps;
  bookingId?: string;
  loading?: boolean;
}) => {
  const [cancellationPending, setPending] = useState(false);

  const { configStore, bookingsStore, confirmStore, errorStore } = useRootStore();
  const onCancelService = async () => {
    const hotelId = configStore.hotelId;

    if (!bookingId || !hotelId) {
      return;
    }

    const confirmed = await confirmStore.confirm({
      title: 'Внимание!',
      text: 'Вы действительно хотите удалить эту услугу?',
    });

    if (!confirmed) {
      return;
    }

    try {
      setPending(true);
      await cancelService({ hotelId, bookingId: Number(bookingId), serviceId: service.id });

      bookingId && (await bookingsStore.loadBookingInfo(bookingId));
    } catch (err) {
      errorStore.setError(err as Error);
    } finally {
      setPending(false);
    }
  };

  return (
    <article
      className={`flex flex-col justify-between gap-2  pb-4 pt-3 xl:flex-row xl:gap-5 ${service.refuse_time ? 'text-neutral-50' : ''}`}
    >
      <div className={'flex flex-col text-lg'}>
        <p className="mb-1 font-medium leading-5">
          <Skeleton loading={loading} className="h-5 w-[240px]">
            {service.service.name}
            {service.sub_service?.name ? `: ${service.sub_service.name}` : ''}
          </Skeleton>
        </p>
        <p className="font-light">
          <Skeleton loading={loading} className="h-5 w-[80px]">
            {service.price} &#8381;
          </Skeleton>
        </p>
      </div>
      <Skeleton loading={loading} className="h-6 w-[92px]">
        {service.refuse_time ? (
          <div className="flex items-center text-sm">
            Отменено: {format(service.refuse_time, 'yyyy.MM.dd HH:mm:ss')}
          </div>
        ) : (
          <ButtonWithIcon
            text="Удалить"
            icon={<BinIcon />}
            onClick={onCancelService}
            pending={cancellationPending}
          />
        )}
      </Skeleton>
    </article>
  );
};
