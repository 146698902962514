import { useEffect } from 'react';
import { Heading2 } from '../../components/shared/Heading';
import { useRootStore } from '../../stores/useRootStore';
import { BackLink } from '../Rates/Rates';
import { Service } from './Service';
import { observer } from 'mobx-react-lite';
import { WithOrderSummaryLayout } from '../../components/WithOrderSummaryLayout';
import { useRedirectToHomePage } from '../../useRedirectToHomePage';
import { Navigate } from 'react-router-dom';
import { Skeleton } from '../../components/Skeleton';
import { Category } from '../RoomList';

export const Services = observer(() => {
  const { paramsStore, configStore } = useRootStore();

  useRedirectToHomePage();

  useEffect(() => {
    paramsStore.fetchServices();
  }, [paramsStore]);

  const addService = (params: { serviceId: number; quantity?: number }) => {
    const { serviceId, quantity } = params;

    const service = paramsStore.getService(serviceId);

    service &&
      paramsStore.toggleService({
        serviceId: service.id,
        name: service.name,
        price: service.price,
        quantity: quantity ?? 1,
      });
  };

  const addSubService = (params: {
    serviceId: number;
    subServiceId: number;
    name: string;
    price: number;
  }) => {
    const { serviceId, subServiceId, price, name } = params;
    const service = paramsStore.getService(params.serviceId);

    service && paramsStore.toggleSubService({ serviceId, subServiceId, price, name, quantity: 1 });
  };

  const addQuantityService = (params: {
    serviceId: number;
    quantity: number;
    priceAnnotation: string;
  }) => {
    const { serviceId, quantity, priceAnnotation } = params;
    const service = paramsStore.getService(params.serviceId);

    service &&
      paramsStore.toggleQuantityService({
        serviceId,
        quantity,
        name: service.name,
        price: service.price,
        priceAnnotation,
      });
  };

  return (
    <WithOrderSummaryLayout>
      <div>
        {configStore.shoudSkipServices && <Navigate to="/form" replace />}
        <BackLink to="/rates">К выбору тарифа</BackLink>
        <Heading2 className="mt-8">Дополнительные услуги</Heading2>

        <section className="flex space-x-4 overflow-auto px-5 pb-8 xl:space-x-7 xl:px-0 xl:pb-5 3xl:absolute 3xl:left-[78px] 3xl:flex-col 3xl:space-x-0 3xl:space-y-4">
          {paramsStore.serviceList.pending && (
            <>
              <Skeleton loading className="h-7 w-[80px]"></Skeleton>
              <Skeleton loading className="h-7 w-[80px]"></Skeleton>
            </>
          )}
          {!paramsStore.serviceList.pending &&
            paramsStore.serviceList.categories.map((category) => (
              <Category
                key={category}
                selected={paramsStore.serviceList.category === category}
                onClick={(c) => paramsStore.serviceList.setCategory(c)}
              >
                {category}
              </Category>
            ))}
        </section>


        {paramsStore.serviceList.getListByCategory().map(service => (
          <Service
            key={service.id}
            service={service}
            addService={serviceId => addService(serviceId)}
            addSubService={params => addSubService(params)}
            addQuantityService={params => addQuantityService(params)}
          />
        ))}
      </div>
    </WithOrderSummaryLayout>
  );
});
