import { useMatch, useNavigate } from 'react-router-dom';
import { useAnimatedDialogHandler } from './shared/Dialog/useAnimatedDialogHandler';
import { ReactNode } from 'react';
import { OrderSummary } from '../views/OrderSummary';
import { StickyBottom } from './shared/StickyBottom/StickyBottom';
import { Button } from './shared/Button/Button';
import InfoIcon from '../assets/info-icon.svg?react';

export const WithOrderSummaryLayout = ({ children }: { children: ReactNode }) => {
  const isServices = useMatch('/services');
  const { ref, isOpen, openDialog, closeDialog } = useAnimatedDialogHandler(300);

  const navigate = useNavigate();

  const proceed = () => {
    // navigate('/services');
    navigate('/form');
  };

  return (
    <main className="container mx-auto max-w-[1168px]">
      <div className="xl:mt-10 xl:grid xl:grid-cols-fixed-right">
        <section className="p-5 xl:mr-6 xl:p-8 xl:pt-0">{children}</section>

        <section className="hidden h-fit bg-neutral-10 p-8 xl:block sticky top-8">
          <OrderSummary />
        </section>
      </div>
      <dialog
        ref={ref}
        className={`custom-popup m-0 h-full max-h-full w-full max-w-full bg-neutral-0/0 xl:hidden ${isOpen && 'open'}`}
      >
        <div
          className={`fixed bottom-0 table-cell h-full w-full max-w-full overflow-x-auto bg-neutral-0 p-5`}
        >
          <OrderSummary onClose={closeDialog} />
        </div>
      </dialog>

      {isServices && (
        <StickyBottom className="hidden xl:block">
          <Button onClick={proceed}>Далее</Button>
        </StickyBottom>
      )}

      <StickyBottom className="xl:hidden">
        {isServices && (
          <Button onClick={proceed} className="mb-3 w-full">
            Далее
          </Button>
        )}
        <div className="flex items-center justify-center">
          <p className="mr-2 text-lg text-neutral-900" onClick={openDialog}>
            Информация о заказе
          </p>
          <InfoIcon />
        </div>
      </StickyBottom>
    </main>
  );
};
