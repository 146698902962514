import { Link, Navigate, useMatch, useRouteError } from 'react-router-dom';
import { NotFoundPlug } from '../components/Plug/NotFoundPlug';
import { Button } from '../components/shared/Button/Button';

type RouteError = {
  statusText: string;
  message: string;
};

export const ErrorPage = () => {
  const error = useRouteError();

  return (
    <div className="container mx-auto mt-10 max-w-[870px]">
      {useMatch('mriya') && <Navigate to={'/'} />}
      <NotFoundPlug
        title="Страница не найдена"
        text={(error as RouteError).statusText || (error as RouteError).message}
      >
        <Link to="/">
          <Button>Перейти в модуль бронирования</Button>
        </Link>
      </NotFoundPlug>
    </div>
  );
};
