import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { InputField } from '../shared/InputField/InputField';
import { Button } from '../shared/Button/Button';
import { Heading3 } from '../shared/Heading';
import { useRootStore } from '../../stores/useRootStore';
import { observer } from 'mobx-react-lite';

const registerOptions: RegisterOptions = {
  required: {
    value: true,
    message: 'Это поле обязательно для заполенени',
  },
  minLength: { value: 3, message: 'Минимальная длина 3 символа' },
};

export const Promocode = observer(() => {
  const { paramsStore } = useRootStore();
  const { failed, success, pending } = paramsStore.promocodeValidationState;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ promocode: string }>();

  const onSubmit: SubmitHandler<{ promocode: string }> = async ({ promocode }) => {
    const isPromocodeValid = await paramsStore.validatePromocode(promocode);
    isPromocodeValid && paramsStore.setPromocode(promocode);
  };

  return (
    <>
      <Heading3 className="text-center">Промокод</Heading3>
      <form
        className="relative mx-auto mb-4 mt-8 max-w-[547px] items-end xl:flex"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          label="ваш промокод"
          placeholder="Кодовое слово"
          type="text"
          className="flex-1"
          {...register('promocode', registerOptions)}
          error={errors['promocode']?.message}
        />
        <Button
          className="mt-3 w-full xl:ml-5 xl:mt-0 xl:w-auto "
          variant="outlined"
          pending={pending}
        >
          Применить
        </Button>
        {success && (
          <span className="mt-3 block text-sm font-light text-success-200 xl:absolute xl:-right-5 xl:bottom-0 xl:ml-5 xl:mt-0 xl:translate-x-full">
            Промокод успешно
            <br className="hidden xl:inline" /> применён!
          </span>
        )}
        {failed && (
          <span className="mt-3 block text-sm font-light text-critical-300 xl:absolute xl:-right-5 xl:bottom-0 xl:ml-5 xl:mt-0 xl:translate-x-full">
            Неизвестный промокод
          </span>
        )}
      </form>
    </>
  );
});
