import {
  CaptionProps,
  DateRange,
  DayContent,
  DayContentProps,
  DayPicker,
  SelectRangeEventHandler,
  useNavigation,
} from 'react-day-picker';
import ArrowLeftIcon from '../../assets/arrow-left.svg?react';
import ArrowRightIcon from '../../assets/arrow-right.svg?react';
import 'react-day-picker/dist/style.css';
import './react-day-picker-override.css';

import { useContext, useEffect, useState } from 'react';
import { format, startOfMonth, subDays } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useMonthsNumber as useResponsiveMonthsNumber } from './useMonthsNumber';
import { CalendarContext, useAvailability } from './Availability';
import { Button } from '../shared/Button/Button.tsx';
import { useRootStore } from '../../stores/useRootStore.ts';
import { observer } from 'mobx-react-lite';
import { differenceInDays } from 'date-fns/differenceInDays';
import noun from 'plural-ru';

const CustomCaption = (props: CaptionProps) => {
  const { displayIndex: index } = props;

  const { goToMonth, nextMonth, previousMonth, currentMonth } = useNavigation();

  const { colorAvailabilityStore: availabilityStore } = useRootStore();

  useEffect(() => {
    const monthStart = startOfMonth(currentMonth);
    availabilityStore.setAvailabilityStart(monthStart);
  }, [currentMonth, availabilityStore]);

  return (
    <div className="mb-4 mt-5 flex items-center justify-between lg:mt-9 ">
      <Button
        style="secondary"
        variant="link"
        onClick={() => previousMonth && goToMonth(previousMonth)}
        className={`${index == 1 && 'lg:invisible'} `}
      >
        <ArrowLeftIcon />
      </Button>
      <span className="font-title text-xl font-light uppercase xl:text-2xl">
        {format(props.displayMonth, 'LLLL yyyy', { locale: ru })}
      </span>
      <Button
        style="secondary"
        variant="link"
        onClick={() => nextMonth && goToMonth(nextMonth)}
        className={`${index == 0 && 'lg:invisible'}`}
      >
        <ArrowRightIcon />
      </Button>
    </div>
  );
};

const Legend = () => {
  const { colors } = useContext(CalendarContext);

  return (
    <div className="px-5 pb-4 xl:flex xl:flex-wrap xl:gap-2 xl:px-0 xl:pt-3">
      {Object.values(colors).map(({ legend, color }) => (
        <div key={legend} className="flex xl:px-2">
          <div
            className="m-2 h-2 w-2 rounded-full bg-primary-100"
            style={{ backgroundColor: color }}
          ></div>
          <div>- {legend}</div>
        </div>
      ))}
    </div>
  );
};

const CustomDayContent = (props: DayContentProps) => {
  const { date, activeModifiers, displayMonth } = props;
  const { colors, prices } = useContext(CalendarContext);

  const availabilityKeys = Object.keys(colors);

  const [key] = Object.keys(activeModifiers).filter((key) => availabilityKeys.includes(key));
  const color = colors[key]?.color;

  const pricekeys = Object.keys(prices);
  const [priceKey] = Object.keys(activeModifiers).filter((priceKey) =>
    pricekeys.includes(priceKey),
  );
  const price = prices[priceKey];

  return (
    <>
      <DayContent date={date} activeModifiers={activeModifiers} displayMonth={displayMonth} />
      {color && (
        <div
          className="absolute right-0 top-0 m-0.5 h-1 w-1 rounded-full xl:h-2 xl:w-2"
          style={{ backgroundColor: color }}
        ></div>
      )}
      {price && (
        <div className="custom-price absolute bottom-1 text-[10px] font-light leading-3 text-neutral-200 xl:bottom-2 xl:text-sm">
          {Number(price).toLocaleString()} &#8381;
        </div>
      )}
    </>
  );
};

const getInitialRange = (storeRange?: { from?: Date; to?: Date }) => {
  if (!storeRange) {
    return;
  }
  const { from, to } = storeRange;

  if (!from || !to) {
    return;
  }

  return { from, to };
};

export const Calendar = observer(() => {
  const { paramsStore, colorAvailabilityStore: availabilityStore } = useRootStore();

  const today = new Date();
  const disabledDates = [{ from: new Date(1, 0, 1), to: subDays(today, 1) }];

  const [range, setRange] = useState<DateRange | undefined>(getInitialRange(paramsStore.range));

  const onRangeChange: SelectRangeEventHandler = (nextRange, selectedDate) => {
    setRange((range) => {
      if (range?.from && range.to) {
        paramsStore.setRange({ from: selectedDate });
        return { from: selectedDate };
      }
      paramsStore.setRange(nextRange);
      return nextRange;
    });
  };

  const numberOfMonths = useResponsiveMonthsNumber();
  const { modifiers, colors, prices } = useAvailability(availabilityStore.availability);

  const [month, setMonth] = useState(range ? range.to : new Date());

  return (
    <div className="flex flex-col lg:relative">
      <CalendarContext.Provider value={{ colors, prices }}>
        <DayPicker
          mode="range"
          selected={range}
          onSelect={onRangeChange}
          month={month}
          onMonthChange={setMonth}
          numberOfMonths={numberOfMonths}
          disabled={disabledDates}
          modifiers={modifiers}
          modifiersClassNames={{ few: 'few' }}
          components={{
            Caption: CustomCaption,
            DayContent: CustomDayContent,
          }}
          locale={ru}
        />
        <Legend />
        <SelectedNights range={range} />
      </CalendarContext.Provider>
    </div>
  );
});

const formatDate = (date?: Date) => date && format(date, 'dd LLL yyyy', {locale: ru })

const SelectedNights = ({ range } : { range?: DateRange }) => {
    const { from, to } = range || {};
    if (!from || !to) {
        return null;
    }

    const nightsCount = Math.abs(differenceInDays(from, to));

    return (
        <section className='flex flex-col gap-2 xl:flex-row my-4 justify-center items-center font-light text-neutral-900'>
            <span>
                Заезд {formatDate(from)} - <span className='font-bold'>выезд {formatDate(to)}</span> 
            </span>
            <span className='text-neutral-200'>({nightsCount} {noun(nightsCount, 'ночь', 'ночи', 'ночей')})</span>
        </section>

    )
}
