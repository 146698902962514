import { api } from '../utils/api';

export type Payment = {
    id: number;
    payment_type: string;
    payment_alias: string;
    part: null | number;
    is_not_pay: boolean;
    hotel: number;
}

export type HotelConfig = {
  id: number;
  hotel_menu_items: {
    name: string;
    reference: string;
    only_for_corp_employee: boolean;
  }[];
  payment_form: {
    id: number;
    payment_type: string;
    details: string;
  };
  hotel_payment_methods: Payment[];
  hotel_reservation_cancel_types: {
    id: number;
    name: string;
    need_comment: boolean;
    sort_number: number;
  }[];
  hotel_child_categories: {
    id: number;
    ext_id: null;
    ts: string;
    is_delete: boolean;
    name: string;
    code: string;
    from_age: number;
    to_age: number;
    use_in_culc: boolean;
    sync_pms: boolean;
    max_childs: number;
    hotel: number;
  }[];
  note: string;
  hotel_style_config: {
    id: number;
    show_header: boolean;
    primary_color: string;
    svg_background_color: string;
    menu_background_color: string;
    hover_color: string;
    splash: string;
    svg: string;
    logo: null;
    favicon: null;
    hotel: number;
  };
  nlb_version: string;
  app_version: string;
  name: string;
  alias: string;
  active: boolean;
  pms_type: string;
  payment_type: string;
  phone1: string;
  phone2: string;
  email1: string;
  email2: string;
  no_reply_email: string;
  web_site: string;
  redirect_base_url: null;
  promo_code: boolean;
  color_availability: boolean;
  lk: boolean;
  feedback_form: boolean;
  order_type: string;
  max_rooms: number;
  max_adults: number;
  awaiting_payments: number;
  awaiting_payments_reminder: number;
  payment_expiration: number;
  ya_script: string;
  ya_counter: null;
  ga_script: string;
  ga_counter: null;
  anonymus: boolean;
  registration: boolean;
  time_arrival: boolean;
  time_departure: boolean;
  time_offset: number;
  rules_and_services: string;
  rules_and_services_open_rate: string | null;
  users_term_open_rate: string | null;
  users_term: string;
  booking_available: boolean;
  use_lk_reservations: boolean;
  use_packages: boolean;
  refund_before_nigth: number;
  refund_nigth_count: number;
  hotel_chain: null;
  default_note: number;
  rules_purchasing_voucher: string;
};

export const getConfig = () => {
  const slug = import.meta.env.VITE_HOTEL_ALIAS;
  return api.get<HotelConfig>(`/api/v1/hotel-config/${slug}/`);
};
